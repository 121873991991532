<template>
  <div>
    <modal-box
      :is-active="isModalActive"
      :trash-object-name="trashObjectName"
      @confirm="trashConfirm"
      @cancel="trashCancel"
    />
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      {{ heroTitle }}
      <router-link slot="right" :to="{ name: 'users' }" class="button">
        Manage Users
      </router-link>
    </hero-bar>
    <section class="section is-main-section">
      <tiles>
        <card-component
          :title="formCardTitle"
          icon="account-edit"
          class="tile is-child"
        >
          <form @submit.prevent="submit">
            <b-field label="Role Name" horizontal>
              <b-input
                v-model="form.name"
                placeholder="e.g. Can View Ticket"
                required
              />
            </b-field>
            <b-field label="Role Slug" horizontal>
              <b-input
                v-model="form.slug"
                placeholder="e.g. can-view-ticket"
                required
              />
            </b-field>
            <b-field label="Permissions" horizontal>
                <v-select multiple  label="value" placeholder="Add permissions" :filterable="false" class="style-chooser" v-model="form.permissions" :options="permissions" @search="onPermissionSearch">
                    <template slot="no-options">
                    type to search permissions ...
                    </template>
                    <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                            {{ option.text }}
                        </div>
                    </template>
                </v-select>
            </b-field>

            <!-- <b-field label="Status" horizontal>
                <b-select placeholder="Is Active?" v-model="form.is_active">
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                </b-select>
            </b-field> -->
            <hr />
            <b-field horizontal>
              <b-button
                type="is-primary"
                :loading="isLoading"
                native-type="submit"
                >Submit</b-button
              >
            </b-field>
          </form>
        </card-component>
        <card-component
          v-if="isProfileExists"
          title="Role Details"
          icon="account"
          class="tile is-child"
          header-icon="delete"
          @header-icon-click="trashModal(form);$router.replace({ name: 'roles' })"
        >
          <b-field grouped>
            <b-field label="Id">
              <b-input :value="form.id" custom-class="is-static" readonly />
            </b-field>
            <b-field label="Name">
              <b-input :value="form.name" custom-class="is-static" readonly />
            </b-field>
            <b-field label="Slug">
              <b-input :value="form.slug" custom-class="is-static" readonly />
            </b-field>
          </b-field>
          <b-field label="Permissions">
              <b-input v-if="form.permissions.length" :value="form.permissions.map(p => p.text).join(', ')" custom-class="is-static" readonly />
              <b-input v-else value="No permissions" custom-class="is-static" readonly />
          </b-field>
          <b-field label="Created At">
            <b-input
              :value="form.created_at"
              custom-class="is-static"
              readonly
            />
          </b-field>
          <b-field label="Updated At">
            <b-input
              :value="form.updated_at"
              custom-class="is-static"
              readonly
            />
          </b-field>
        </card-component>
      </tiles>
    </section>
  </div>
</template>

<script>
// import dayjs from 'dayjs'
import isEmpty from 'lodash/isEmpty'
import ModalBox from '@/components/ModalBox'
import debounce from 'lodash/debounce'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import Tiles from '@/components/Tiles'
import CardComponent from '@/components/CardComponent'
import { mapActions, mapState } from 'vuex'
import vSelect from 'vue-select'
import { apiService } from '@/services'

export default {
  name: 'RoleForm',
  components: {
    CardComponent,
    Tiles,
    HeroBar,
    TitleBar,
    ModalBox,
    vSelect
    // ,
    // Notification
  },
  props: {
    id: {
      type: [String, Number],
      default: null
    }
  },
  data () {
    return {
      isLoading: false,
      form: this.getClearFormObject(),
      isModalActive: false,
      trashObject: null,
      createdReadable: null,
      permissions: [], // Used in options
      isProfileExists: false
    }
  },
  computed: {
    trashObjectName () {
      if (this.trashObject) {
        return this.trashObject.name
      }
      return null
    },
    titleStack () {
      const lastCrumb = this.isProfileExists ? this.form.name : 'New Role'
      return ['Admin', 'Roles', lastCrumb]
    },
    heroTitle () {
      return this.isProfileExists ? this.form.name : 'Create Role'
    },
    formCardTitle () {
      return this.isProfileExists ? 'Edit Role' : 'New Role'
    },
    ...mapState('role', ['item', 'formError'])
  },
  watch: {
    id (newValue) {
      this.isProfileExists = false
      if (!newValue) {
        this.form = this.getClearFormObject()
      } else {
        this.getData()
      }
    }
  },
  created () {
    this.getData()
  },
  methods: {
    ...mapActions('role', ['getItem', 'addItem', 'updateItem', 'deleteItem']),
    getClearFormObject () {
      return {
        id: null,
        name: null,
        permissions: [],
        is_active: null,
        created_at: null,
        updated_at: null
      }
    },
    trashModal (trashObject) {
      this.trashObject = trashObject
      this.isModalActive = true
    },
    trashConfirm () {
      this.isModalActive = false
      this.deleteItem(this.trashObject.id).then(() => {
        this.$router.replace({ name: 'users' })
      })
    },
    trashCancel () {
      this.isModalActive = false
    },
    getData () {
      if (this.$route.params.id) {
        this.getItem(this.$route.params.id).then(() => {
          // console.log(this.item)
          if (!isEmpty(this.item)) {
            this.isProfileExists = true
            this.form = this.item
          } else {
            this.$router.push({ name: 'role.new' })
          }
        })
      }
    },
    onPermissionSearch (search, loading) {
      if (search.length) {
        loading(true)
        this.search(loading, 'permissions', search, this)
      }
    },
    search: debounce((loading, endpoint, search, vm) => {
      apiService.getAll(`/options/${escape(endpoint)}?q=${escape(search)}`).then(response => {
        vm[endpoint] = response.data
        loading(false)
      }).catch(error => {
        vm.showError(error)
      })
    }, 350),
    submit () {
      this.isLoading = true
      if (!this.form.id) {
        this.addItem(this.form).then(() => {
          if (!this.formError) this.$router.push({ name: 'users' })
          this.isLoading = false
        }).catch(() => { this.isLoading = false })
      } else {
        this.updateItem(this.form).then(() => {
          this.isLoading = false
          this.form = this.item
        }).catch(() => { this.isLoading = false })
      }
    }
  }
}
</script>
